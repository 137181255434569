import React from 'react';
import { Helmet } from 'react-helmet';

import alvorada from "./assets/alvorada.jpeg";
import feliz from "./assets/feliz.jpeg";
import outros from "./assets/outros.jpeg";

import './style.sass';


const Responsabilidade = () => (
  <main className="Responsabilidade">
    <Helmet>
      <title>Responsabilidade Social | FRIBAL - Especialistas em Carne</title>
    </Helmet>
    <section className="Responsabilidade__content">
      <h2 className="Responsabilidade__title">Responsabilidade Social</h2>
      <div className="Responsabilidade__container">
        <section className="Responsabilidade__projeto">
          <h3 className="Responsabilidade__subtitle title">PROJETO ALVORADA</h3>
          <div className="Responsabilidade__description">
            <p>
              Somos patrocinadores do Projeto Alvorada, uma iniciativa que apoiamos através da Lei
              de Incentivo ao Esporte. Esse projeto vai além do esporte: ele amplia horizontes e cria
              oportunidades para jovens e crianças em situação de vulnerabilidade social.
            </p>
            <p>
              Acreditamos no poder transformador do esporte para o desenvolvimento educacional,
              social e de saúde. É por meio dele que esses jovens encontram não apenas lazer, mas
              também um caminho para o desenvolvimento contínuo de suas habilidades e
              potencialidades, promovendo a harmonia com eles mesmos, suas famílias e suas
              comunidades.
            </p>
            <p>
              Com o Projeto Alvorada, oferecemos uma variedade de atividades esportivas, como
              futebol, futsal, vôlei, futevôlei, judô, jiu-jitsu, skate, atletismo e ballet, para meninos e
              meninas. Além disso, garantimos assistência social gratuita, incluindo apoio
              psicológico, odontológico, fisioterapêutico, médico e pedagógico, bem como cursos
              técnicos profissionalizantes, abrindo portas para um futuro melhor de crianças do
              município de Imperatriz e São Luís.
            </p>
          </div>
          <div className="Responsabilidade__image">
            <img src={alvorada} alt="" />
          </div>
        </section>
        <section className="Responsabilidade__projeto">
          <h3 className="Responsabilidade__subtitle title">PROJETO CRIANÇA FELIZ - ESCOLA SONHO DE CRIANÇA (MOCAJITUBA)</h3>
          <div className="Responsabilidade__description">
            <p>
              A Fribal, com um olhar atento à responsabilidade social, idealizou o Projeto Infância
              Feliz, que deu mais um importante passo para transformar a vida das crianças da
              Escolinha Sonho de Criança, localizada no Porto de Mocajituba, em Paço do Lumiar. A
              escolinha, que é um refúgio de esperança para mais de 80 crianças da comunidade,
              possui quadra esportiva, uma brinquedoteca cheia de cores e sonhos, banheiros
              acessíveis, playground e um quiosque recreativo.
            </p>
            <p>
              Essas melhorias só foram possíveis graças ao comprometimento da Fribal e ao apoio
              do Governo do Estado, através da Lei de Incentivo ao Esporte. Sabemos que cada
              espaço transformado é uma oportunidade de crescimento, aprendizado e diversão
              para essas crianças, e estamos felizes em fazer parte dessa jornada, plantando
              sementes de um futuro mais promissor para todos.
            </p>
          </div>
          <div className="Responsabilidade__image">
            <img src={feliz} alt="" />
          </div>
        </section>
        <section className="Responsabilidade__projeto">
          <h3 className="Responsabilidade__subtitle title">OUTROS PROJETOS DE RESPONSABILIDADE SOCIAL</h3>
          <div className="Responsabilidade__description">
          <p>
            Na Fribal, acreditamos que pequenas ações podem fazer uma grande diferença na vida
            de muitas crianças. Por isso, nossa responsabilidade social vai além dos nossos
            projetos internos. Temos orgulho de manter parcerias com diversas escolas em
            comunidades carentes, onde nos dedicamos a levar um pouco mais de alegria e
            esperança para essas crianças.
          </p>
          <p>
            Todos os anos, durante o Dia das Crianças, nos unimos para distribuir brinquedos,
            transformando o dia delas em um momento de felicidade e diversão. Mas nossa
            contribuição não para por aí. No início do ano letivo, entregamos kits escolares com
            mochilas e itens de papelaria, garantindo que cada criança tenha as ferramentas
            necessárias para começar o ano com entusiasmo e disposição.
          </p>
          <p>
            Essas ações refletem nosso compromisso com o futuro dessas comunidades, pois
            acreditamos que, ao investir na educação e no bem-estar das crianças, estamos
            plantando as sementes para um amanhã mais justo e cheio de oportunidades.
          </p>
          </div>
          <div className="Responsabilidade__image">
            <img src={outros} alt="" />
          </div>
      </section>
    </div>
  </section>

  </main>
);

export default Responsabilidade;
